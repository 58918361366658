<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container>
  <ng-container>
    <div class="p-md-4 bg-md-white mt-5 privacy-bg" id="privacy-back">
      <div class="row">
        <div class="col-md-auto d-none d-md-block">
          <div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <ng-container *ngFor="let item of content; let i = index">
              <ng-container *ngIf="i < 30">
                <button class="nav-link"
                        [ngClass]="getCustomCss(item, i, 'active')"
                        [id]="'heading' + i"
                        data-bs-toggle="pill"
                        [attr.data-bs-target]="'#v-pills-tab' + i" type="button" role="tab"
                        [attr.aria-controls]="'v-pills-tab' + i"
                        aria-selected="true">{{ getAccordionTitle(item?.title, "v-pills-tab" + i) }}
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="col-md col-12 terms-bg-color">
          <div class="tab-content" id="v-pills-tabContent">
            <ng-container *ngFor="let item of content; let i = index">
              <ng-container *ngIf="i < 30">
                <div class="tab-pane fade" [ngClass]=" getCustomCss(item, i, 'show active') "
                     [id]="'v-pills-tab' + i"
                     role="tabpanel" [attr.aria-labelledby]="'heading' + i" tabindex="0">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'heading' + i">
                      <!-- In mobile view, the accordion button is always in collapsed mode. -->
                      <button class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              [attr.data-bs-target]="'#collapse' + i"
                              aria-expanded="false"
                              [attr.aria-controls]="'collapse' + i">
                        {{ getAccordionTitle(item?.title, "heading" + i) }}
                      </button>
                    </h2>
                    <div [id]="'collapse' + i" class="accordion-collapse collapse"
                         [ngClass]="{
                                      show:
                                        currentPath === item?.title.toLowerCase() &&
                                        (openFirstAccordion && i === 0)
                                    }"
                         [attr.aria-labelledby]="'heading' + i"
                         data-bs-parent="#accordionExample">
                      <div class="accordion-body" tabindex="0" [ngClass]="currentPath === 'terms-and-conditions' ? 'terms-condition' : 'current-accordion'" id="scroll-bar" [innerHTML]="item?.content?.content?.value">
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
