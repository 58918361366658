<form [formGroup]="calcForm" *ngIf="calcForm">

  <!-- Earn Amount -->
  <div class="form-group" *ngIf="handleFieldVisibility('earn')">
    <label for="earn" class="form-label">{{ "ftiCalc.calculator-sip-want-earn" | translate }}

      <div class="selected-range">
        <span class="calc-input-prefix">₹</span>
        <input id="earn" name="earn" type="number" (change)="onCalculatorChange($event, 'earn')"
               [(ngModel)]="calcForm.value.earn" formControlName="earn"
               [attr.min]="getInputLimit(sliderKeyName.earn, false)"
               [attr.max]="getInputLimit(sliderKeyName.earn, true)" class="calc-input amount text-start">
      </div>
    </label>
    <div class="range-slider">
      <input type="range" (change)="onCalculatorChange($event, 'earn')"
             [value]="calcForm.value.earn || getInputLimit(sliderKeyName.earn)" formControlName="earn"
             class="custom-range" [attr.min]="getInputLimit(sliderKeyName.earn, false)"
             [attr.max]="getInputLimit(sliderKeyName.earn, true)" [style.background]="earnSliderStyle">
    </div>
    <div class="d-flex text-small justify-content-between">
      <span>₹{{ "ftiCalc.calculator-50L" | translate }}</span>
      <span>₹{{ "ftiCalc.calculator-10Cr" | translate }}</span>
    </div>
  </div>

  <!-- Investment Amount -->
  <div class="form-group" *ngIf="handleFieldVisibility('amount')">
    <label id="iWantToInvest" for="amount" class="form-label">{{ "ftiCalc.calculator-want-invest" | translate }}

      <div class="selected-range">
        <span class="calc-input-prefix">₹</span>
        <input
            type="number" id="amount"
            [attr.min]="getInputLimit(sliderKeyName.amount, false)"
            [attr.max]="getInputLimit(sliderKeyName.amount, true)"
            [(ngModel)]="calcForm.value.amount"
            formControlName="amount"
            (change)="onCalculatorChange($event, 'amount')"
            class="calc-input amount text-start">
      </div>
    </label>
    <div class="range-slider">
      <input
          type="range"
          [attr.min]="getInputLimit(sliderKeyName.amount, false)"
          [attr.max]="getInputLimit(sliderKeyName.amount, true)"
          [value]="calcForm.value.amount || getInputLimit(sliderKeyName.amount)"
          formControlName="amount"
          (change)="onCalculatorChange($event, 'amount')"
          class="custom-range"
          [style.background]="amountSliderStyle"
          aria-labelledby="iWantToInvest">

    </div>
    <div class="d-flex text-small justify-content-between">
      <span>₹{{ "ftiCalc.calculator-10k" | translate }}</span>
      <span>₹{{ "ftiCalc.calculator-10Cr" | translate }}</span>
    </div>
  </div>

  <!-- Period Year -->
  <div class="form-group" *ngIf="handleFieldVisibility('period')">
    <label id="overAPeriodOf" for="period" class="form-label">
      <span *ngIf="handleFieldVisibility('earn'); else overaYear">
        {{ 'ftiCalc.calculator-after-period' | translate }}
      </span>
      <ng-template #overaYear>
        {{ 'ftiCalc.calculator-foryear' | translate }}
      </ng-template>
      <div class="selected-range" (click)="autoFocus($event, 'period')">
        <input
            type="number"
            [attr.min]="getInputLimit(sliderKeyName.period, false)"
            [attr.max]="getInputLimit(sliderKeyName.period, true)"
            formControlName="period"
            [(ngModel)]="calcForm.value.period"
            (change)="onCalculatorChange($event, 'period')"
            class="calc-input text-end forchangecustomcalculator" id="period">

        <span class="calc-input-prefix">{{ 'ftiCalc.calculator-years' | translate }}</span>
      </div>
    </label>
    <div class="range-slider">
      <input
          type="range"
          formControlName="period"
          [value]="calcForm.value.period || getInputLimit(sliderKeyName.period)"
          (change)="onCalculatorChange($event, 'period')"
          class="custom-range"
          [attr.min]="getInputLimit(sliderKeyName.period, false)"
          [attr.max]="getInputLimit(sliderKeyName.period, true)"
          [style.background]="periodSliderStyle"
          aria-labelledby="overAPeriodOf">

    </div>
    <div class="d-flex text-small justify-content-between">
      <span>{{ "ftiCalc.calculator-one-year" | translate }} </span>
      <span>{{ "ftiCalc.calculator-thirty-year" | translate }} </span>
    </div>
  </div>

  <!-- Expected ROI  -->
  <div class="form-group" *ngIf="handleFieldVisibility('expectedReturn')">
    <label id="ExpectedROR" for="expectedReturn" class="form-label">{{ "ftiCalc.calculator-rate-of-return" | translate }}
      <div class="selected-range" (click)="autoFocus($event, 'expectedReturn')">
        <input type="number" [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
               [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)" formControlName="expectedReturn"
               [value]="calcForm.value.expectedReturn || getInputLimit(sliderKeyName.expectedReturn)"
               (change)="onCalculatorChange($event, 'expectedReturn')"
               class="calc-input text-end forchangecustomcalculator" id="expectedReturn">
        <span class="calc-input-prefix"> %</span>
      </div>
    </label>
    <div class="range-slider">
      <input

          type="range"
          formControlName="expectedReturn"
          [value]="calcForm.value.expectedReturn || getInputLimit(sliderKeyName.expectedReturn)"
          (change)="onCalculatorChange($event, 'expectedReturn')"
          class="custom-range"
          [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
          [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)"
          [style.background]="expectedReturnSliderStyle"
          aria-labelledby="ExpectedROR">

    </div>
    <div class="d-flex text-small justify-content-between">
      <span>{{ getInputLimit(sliderKeyName.expectedReturn, false) }}%</span>
      <span>{{ getInputLimit(sliderKeyName.expectedReturn, true) }}%</span>
    </div>
  </div>
</form>
