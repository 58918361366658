<!-- start section calculator -->
<section class="FT-calculator-box mb-6 mt-2">
  <div class="container">
    <h1 class="FT-title-medium text-blue mb-2 mt-2">{{ 'ftiCalc.calculator-swp-heading' | translate }}</h1>
    <div>
      {{ 'ftiCalc.calculator-swp-description' | translate }}
    </div>
    <div class="FT-financial">
      <!-- Tab panes -->
      <div class="tab-content calculator-box">
        <div class="tab-pane active" id="Systematic" role="tabpanel" aria-labelledby="Systematic-tab"
             tabindex="0">
          <div class="tab-content">
            <div class="tab-pane active" id="Investment" role="tabpanel" aria-labelledby="Investment-tab"
                 tabindex="0">
              <div class="row align-items-center">
                <!-- Section: Form -->
                <div class="col-lg-6 col-12">
                  <form [formGroup]="calcForm" *ngIf="calcForm">
                    <!-- Amount Source Fund -->
                    <div class="form-group">
                      <label id="investingAmount" class="form-label">
                        <span class="label-text"> {{ 'ftiCalc.calculator-want-invest' | translate }}</span>
                        <div class="selected-range">
                          <span class="calc-input-prefix">₹</span>
                          <input
                              type="number"
                              [attr.min]="getInputLimit(sliderKeyName.amount, false)"
                              [attr.max]="getInputLimit(sliderKeyName.amount, true)"
                              [(ngModel)]="calcForm.value.amount"
                              formControlName="amount"
                              (change)="onCalculatorChange($event, 'amount')"
                              class="calc-input amount text-start">
                        </div>
                      </label>
                      <div class="range-slider">
                        <input
                            type="range"
                            [attr.min]="getInputLimit(sliderKeyName.amount, false)"
                            [attr.max]="getInputLimit(sliderKeyName.amount, true)"
                            [value]="calcForm.value.amount || getInputLimit(sliderKeyName.amount)"
                            formControlName="amount"
                            (change)="onCalculatorChange($event, 'amount')"
                            class="custom-range"
                            [style.background]="amountSliderStyle"
                            aria-labelledby="investingAmount">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>₹{{ "ftiCalc.calculator-10k" | translate }}</span>
                        <span>₹{{ "ftiCalc.calculator-10Cr" | translate }}</span>
                      </div>
                    </div>
                    <!-- Withdraw & time -->
                    <div class="form-group">
                      <label id="withdrawAmount" class="form-label">
                        <span class="label-text"> {{ 'ftiCalc.calculator-swp-withdraw' | translate }}</span>
                        <div class="selected-range">
                          <span class="calc-input-prefix">₹</span>
                          <input type="number" style="width: 100%;"
                                 [attr.min]="getInputLimit(sliderKeyName.withdraw, false)"
                                 [attr.max]="getInputLimit(sliderKeyName.withdraw, true)"
                                 [(ngModel)]="calcForm.value.withdraw"
                                 formControlName="withdraw"
                                 (change)="onCalculatorChange($event, 'withdraw')"
                                 class="calc-input withdraw text-start">
                        </div>
                      </label>
                      <div class="invest-options">
                        <div class="form-check">
                          <input formControlName="time" value="MONTHLY" class="form-check-input" type="radio"
                                 name="time"
                                 id="Monthly"
                                 (change)="onCalculatorChange($event, 'time')">
                          <label class="form-check-label" for="Monthly">
                            {{ "ftiCalc.calculator-lumpsum-text4" | translate }}
                          </label>
                        </div>
                        <div class="form-check">
                          <input formControlName="time" value="QUARTERLY" class="form-check-input" type="radio"
                                 name="time"
                                 id="Quarterly"
                                 (change)="onCalculatorChange($event, 'time')">
                          <label class="form-check-label" for="Quarterly">
                            {{ "ftiCalc.calculator-quarterly-text1" | translate }}
                          </label>
                        </div>
                        <div class="form-check">
                          <input formControlName="time" value="HALFYEARLY" class="form-check-input" type="radio"
                                 name="time"
                                 id="Halfyearly"
                                 (change)="onCalculatorChange($event, 'time')">
                          <label class="form-check-label" for="Halfyearly">
                            {{ "ftiCalc.calculator-halfyearly-text1" | translate }}
                          </label>
                        </div>
                        <div class="form-check">
                          <input formControlName="time" value="ANNUAL" class="form-check-input"
                                 type="radio" name="time" id="Annual"
                                 (change)="onCalculatorChange($event, 'time')" for="Annual">
                          <label class="form-check-label" for="Annual">
                            {{ "ftiCalc.calculator-annual-text1" | translate }}
                          </label>
                        </div>
                      </div>
                      <div class="range-slider">
                        <input
                            type="range"
                            [attr.min]="getInputLimit(sliderKeyName.withdraw, false)"
                            [attr.max]="getInputLimit(sliderKeyName.withdraw, true)"
                            [value]="calcForm.value.withdraw || getInputLimit(sliderKeyName.withdraw)"
                            formControlName="withdraw"
                            (change)="onCalculatorChange($event, 'withdraw')"
                            class="custom-range"
                            [style.background]="withdrawSliderStyle"
                            aria-labelledby="withdrawAmount">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>₹{{ "ftiCalc.calculator-500" | translate }}</span>
                        <span>₹{{ "ftiCalc.calculator-10L" | translate }}</span>

                      </div>
                    </div>
                    <!-- Period -->
                    <div class="form-group ">
                      <label id="overPeriodOf" class="form-label">
                        <span class="label-text"> {{ 'ftiCalc.calculator-year' | translate }}</span>
                        <div class="selected-range" (click)="autoFocus($event, 'period')">
                          <input
                              type="number"
                              [attr.min]="getInputLimit(sliderKeyName.period, false)"
                              [attr.max]="getInputLimit(sliderKeyName.period, true)"
                              formControlName="period"
                              [(ngModel)]="calcForm.value.period"
                              (change)="onCalculatorChange($event, 'period')"
                              class="calc-input text-end forchangecustomcalculator" id="period">
                          <span class="calc-input-prefix ms-1"> {{ "ftiCalc.calculator-years" | translate }}</span>
                        </div>
                      </label>
                      <div class="range-slider">
                        <input
                            type="range"
                            formControlName="period"
                            [value]="calcForm.value.period || getInputLimit(sliderKeyName.period)"
                            (change)="onCalculatorChange($event, 'period')"
                            class="custom-range"
                            [attr.min]="getInputLimit(sliderKeyName.period, false)"
                            [attr.max]="getInputLimit(sliderKeyName.period, true)"
                            [style.background]="periodSliderStyle"
                            aria-labelledby="overPeriodOf">
                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ getInputLimit(sliderKeyName.period, false) }} {{
                                "ftiCalc.calculator-years" | translate
                          }}</span>
                        <span>{{ getInputLimit(sliderKeyName.period, true) }} {{
                                "ftiCalc.calculator-years" | translate
                          }}</span>
                      </div>
                    </div>

                    <!-- Expected ROR -->
                    <div class="form-group ">
                      <label id="expectedROR" class="form-label">
                        <span class="label-text">{{ "ftiCalc.calculator-rate-of-return" | translate }}</span>
                        <div class="selected-range" (click)="autoFocus($event, 'expectedROR')">
                          <input
                              type="number"
                              [attr.min]="getInputLimit(sliderKeyName.expectedROR, false)"
                              [attr.max]="getInputLimit(sliderKeyName.expectedROR, true)"
                              formControlName="expectedROR"
                              [(ngModel)]="calcForm.value.expectedROR"
                              (change)="onCalculatorChange($event, 'expectedROR')"
                              class="calc-input text-end forchangecustomcalculator" id="expectedROR">
                          <span class="calc-input-prefix"> %</span>
                        </div>
                      </label>
                      <div class="range-slider">
                        <input
                            type="range"
                            formControlName="expectedROR"
                            [value]="calcForm.value.expectedROR || getInputLimit(sliderKeyName.expectedROR)"
                            (change)="onCalculatorChange($event, 'expectedROR')"
                            class="custom-range"
                            [attr.min]="getInputLimit(sliderKeyName.expectedROR, false)"
                            [attr.max]="getInputLimit(sliderKeyName.expectedROR, true)"
                            [style.background]="expectedRORSliderStyle"
                            aria-labelledby="expectedROR"
                        >

                      </div>
                      <div class="d-flex text-small justify-content-between">
                        <span>{{ getInputLimit(sliderKeyName.expectedROR, false) }}%</span>
                        <span>{{ getInputLimit(sliderKeyName.expectedROR, true) }}%</span>
                      </div>
                    </div>
                  </form>
                  <div class="mb-2">
                    <a class="login-dark2" href="" data-bs-toggle="modal"
                       data-bs-target="#exampleModal"> {{ 'ftiCalc.calculator-sip-disclaimer' |   translate }}</a>
                  </div>
                </div>

                <!-- Section: graph & table -->
                <div class="col-lg-6 col-12 ps-lg-4">
                  <div class="calculate-statics">

                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="Graph-tab-pane" role="tabpanel"
                           aria-labelledby="Graph-tab" tabindex="0">
                        <div class="table-responsive text-center">
                          <table class="table table-success table-striped stiky-head">
                            <thead>
                            <tr>
                              <th>{{ 'ftiCalc.calculator-end-of-year' | translate }}</th>
                              <th>{{ 'ftiCalc.calculator-amount-invested' | translate }}</th>
                              <th> {{ 'ftiCalc.calculator-swp-amount-withdrawn' | translate }}</th>
                              <th>{{ 'ftiCalc.calculator-investment-returns' | translate }}</th>
                              <th>{{ 'ftiCalc.calculator-invest-value' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let val of calculatedData.eachYearData">
                              <td>{{ val.year }}</td>
                              <td>{{ val.investedAmt | INRCurrency }}</td>
                              <td>{{ val.withdrawnAmt | INRCurrency }}</td>
                              <td>{{ val.returnsVal | INRCurrency }}</td>
                              <td>{{ val.balanceVal | INRCurrency }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="p-3 bg-light-blue">
                      <div class="numbers row justify-content-center text-center g-4 mb-4">
                        <div class="col-6">
                          <div>{{ 'ftiCalc.calculator-invest-text3' | translate }}</div>
                          <ng-container>
                            <h3 class="sip-recommended-funds">
                              {{ calculatedData?.investedAmt | INRCurrency }}
                            </h3>
                          </ng-container>
                        </div>
                        <div class="col-6">
                          <div> {{ 'ftiCalc.calculator-swp-amount-withdrawn' | translate }}</div>
                          <h3 class="sip-recommended-funds">
                            {{ calculatedData?.totalWithdrawnAmt | INRCurrency }}
                          </h3>
                        </div>
                        <div class="col-6">
                          <div>{{ 'ftiCalc.calculator-investment-returns' | translate }}</div>
                          <h3 class="sip-recommended-funds">
                            {{ calculatedData?.totalReturnsVal | INRCurrency }}
                          </h3>
                        </div>
                        <div class="col-6">
                          <div> {{ 'ftiCalc.calculator-swp-balance' | translate }}</div>
                          <h3 class="sip-recommended-funds">
                            {{ calculatedData?.totalBalanceVal | INRCurrency }}
                          </h3>
                        </div>
                      </div>
                      <div class="equity-risk-boxs" *ngIf="showRiskOptions">
                        <span class="text-bold me-2">Select your equity risk</span>
                        <div class="form-check custom-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault"
                                 id="equity1">
                          <label class="form-check-label" for="equity1">
                            10%
                          </label>
                        </div>
                        <div class="form-check custom-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault"
                                 id="equity2">
                          <label class="form-check-label" for="equity2">
                            20%
                          </label>
                        </div>
                        <div class="form-check custom-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault"
                                 id="equity3">
                          <label class="form-check-label" for="equity3">
                            30%
                          </label>
                        </div>
                        <div class="form-check custom-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault"
                                 id="equity4">
                          <label class="form-check-label" for="equity4">
                            40%
                          </label>
                        </div>
                        <div class="form-check custom-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault"
                                 id="equity5">
                          <label class="form-check-label" for="equity5">
                            50%
                          </label>
                        </div>
                      </div>
                      <button (click)="investNow('')" class="btn btn-primary recommende-btn">
                        {{ 'ftiCalc.calculator-invest-now' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-end">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div>
          {{ 'ftiCalc.sip-calc-disclaimer-pop-up' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
