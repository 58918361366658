import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GraphQLFundDataService,
  FundDataServiceParams,
} from './graphql-fund-data.service';
import { DocumentNode } from '@apollo/client/core';
import { Logger } from '@utils/logger';
import { Apollo } from 'apollo-angular';
import { ProductDTO } from '@types';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { map } from 'rxjs/operators';
import { ProductMapper } from '@products/utils/mappers/product.type.mapper';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { SiteConfigService } from '@services/site-config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppStateService } from '@services/app-state.service';
import { Product } from '../models/product';
import { EnvConfigService } from '@services/env-config.service';
import { SessionService } from '@services/session.service';

const logger = Logger.getLogger('FundDetailService');

@Injectable({
  providedIn: 'root',
})
export class FundDetailService extends GraphQLFundDataService {
  environment: string;

  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private productMapperFactory: MapperFactory,
    private http: HttpClient,
    private appStateService: AppStateService,
    private envConfig: EnvConfigService,
    private sessionService: SessionService
  ) {
    super(apollo, siteConfigService);
    this.environment = this.envConfig.getEnvConfig()?.environment;
  }

  public register(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<Product> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((productDetail) => {
        const productDto: ProductDTO = productDetail?.data?.ProductDetails
          ? productDetail.data.ProductDetails
          : null;
        const mappperParams: MapperParams = {
          config: this.siteConfigService,
          includeSoftClosed: true,
        };

        return productDto
          ? this.productMapperFactory
            .createMapper(ProductMapper, mappperParams)
            .toDomain(productDto)
          : null;
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      fundid: fundDataServiceParams.fundId,
    };
  }

  getFundPerformanceData(req) {
    const options =
      this.sessionService.apiHttpOptions;
    return this.http.post(
      this.appStateService.getPerformanceCummulativeUrl(),
      req,
      options
    );
  }
}
