import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Logger } from '@utils/logger';
import { SessionService } from '@services/session.service';
import { Subscription } from 'rxjs';
import { InUserType } from '@types';
import { EnvConfigService } from '@services/env-config.service';

const logger = Logger.getLogger('NpsComponent');

@Component({
  selector: 'ft-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss'],
})
export class NpsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;

  feedbackForm: FormGroup;
  isThankYou = false;
  url = '';
  isFormSubmitted = false;
  urlPrefix = '';
  public validInput = false;
  public validNumber = false;
  private userType = '';
  private channel = '';
  public hovered;
  public rating;
  public remarks;
  public accountNo;
  public inputErr = '';
  public APIerr = false;

  // Resource Bundle
  flashMessage: string;
  labelFeedback: string;
  labelPlaceHolder: string;
  labelSubmit: string;
  labelReset: string;
  specialCharactersNotAllowed: string;
  queryParams: Params;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 needs mor than 7 parameters in constructor
    private http: HttpClient,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private envConfig: EnvConfigService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.feedbackForm = this.formBuilder.group({
      accountNo: '',
      remarks: '',
    });
  }

  ngOnInit(): void {
    // Get values from Query params
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.userType = this.queryParams?.type || '10';
    this.channel = this.queryParams?.channel || 'web';
    // Get values from resource bundle
    this.urlPrefix = this.envConfig.getEnvConfig()?.ftiApiDomain;
    this.url =
      this.urlPrefix + this.translateService.instant('ftiFeedback.formURL');
    this.flashMessage = this.translateService.instant(
      'ftiFeedback.flashMessage'
    );
    this.labelFeedback = 'Remarks (Optional)';
    this.labelSubmit = this.translateService.instant('ftiFeedback.labelSubmit');
    this.labelReset = this.translateService.instant('ftiFeedback.labelReset');
    this.labelPlaceHolder =
      this.userType === InUserType.ADVISOR
        ? 'ARN- / RIA code'
        : 'PAN / Customer Folio';
  }

  public onKeyUp(): void {
    let enteredValue = this.feedbackForm.value.accountNo;
    enteredValue = enteredValue
      ?.replace(/[\/\\$'"<>]/g, '')
      .toLocaleUpperCase();
    this.feedbackForm.controls.accountNo.setValue(enteredValue);
    this.validNumber =
      this.userType === InUserType.ADVISOR
        ? this.checkIfValidDistCode(enteredValue)
        : this.checkIfValidInvCode(enteredValue);
  }

  private checkIfValidDistCode(code) {
    if (
      code.startsWith('ARN-') ||
      code.startsWith('INA') ||
      code.startsWith('INB') ||
      code.startsWith('INP') ||
      code.startsWith('INZ')
    ) {
      return true;
    }
    return false;
  }

  private checkIfValidInvCode(code) {
    if (/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(code) || /^(\d){8,13}$/.test(code)) {
      return true;
    }
    return false;
  }

  public formSubmit() {
    if (this.validInput) {
      this.feedbackSubmit();
    } else {
      this.panFolioArnSubmit();
    }
  }

  public panFolioArnSubmit() {
    this.isFormSubmitted = true;
    this.inputErr = '';
    this.APIerr = true;
    const reqData = {
      accountNo: this.feedbackForm.value.accountNo,
      name: 'NPS',
    };
    const options =
      this.sessionService.apiHttpOptions;

    const formSubmit: Subscription = this.http
      .post(
        this.urlPrefix + '/v1/ide/services/validateFeedback',
        reqData,
        options
      )
      .subscribe(
        () => {
          this.validInput = true;
          this.isFormSubmitted = false;
          this.changeDetector.markForCheck();
        },
        (error) => {
          this.isFormSubmitted = false;
          this.inputErr =
            error?.error[0]?.errorDescription || 'No records found.';
          this.APIerr = true;
          logger.error('error >>>> ', error);
          this.changeDetector.markForCheck();
        }
      );
  }

  public feedbackSubmit() {
    this.isFormSubmitted = true;
    this.inputErr = '';
    this.APIerr = true;
    const reqData = {
      accountNo: this.feedbackForm.value.accountNo,
      name: 'NPS_' + this.channel?.toLocaleUpperCase(),
      feedback:
        this.feedbackForm.value.accountNo +
        '|' +
        (this.rating - 1) +
        '|' +
        (this.feedbackForm.value.remarks || ''),
      emailId: 'NOEMAIL',
    };
    const options =
      this.sessionService.apiHttpOptions;

    const formSubmit: Subscription = this.http
      .post(this.url, reqData, options)
      .subscribe(
        () => {
          this.isThankYou = true;
          this.isFormSubmitted = false;
          this.changeDetector.markForCheck();
        },
        (error) => {
          this.isFormSubmitted = false;
          this.inputErr =
            error?.error[0]?.errorDescription || 'Something went wrong.';
          this.APIerr = true;
          logger.error('error >>>> ', error);
          this.changeDetector.markForCheck();
        }
      );
  }
}
