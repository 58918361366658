import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppStateService } from '@services/app-state.service';
import { SiteConfigService } from '@services/site-config.service';
import {
  FundId,
  FundIdentifier,
  InUserType,
  ShareClassCode,
  TabName,
} from '@types';
import { forkJoin, of } from 'rxjs';
import uniqBy from 'lodash/uniqBy';
import {
  FtSearchItem,
  FtSearchResponse,
  FtSearchResponseItem,
  FundDataItem,
  FundItem,
} from './search.interface';
import { EnvConfigService } from '@services/env-config.service';
import { LoginService } from '@services/login.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionService } from '@services/session.service';

const funds = 'funds';
const pages = 'pages';
const literature = 'literature';

@Injectable({
  providedIn: 'root',
})
export class FtsearchService implements OnDestroy {
  results = [];
  isAdvisor = false;
  isInvestor = false;
  isLoggedIn = false;
  totalItems = {
    funds: 0,
    general: 0,
    advisor: 0,
    investor: 0,
    literature: 0,
  };
  valueToShow = {
    funds: 3,
    pages: 3,
    advisor: 3,
    investor: 3,
    literature: 3,
  };
  showSections = {
    all: true,
    funds: false,
    literature: false,
    pages: false,
    advisor: false,
    investor: false,
  };
  resultsItems = {
    funds: [],
    pages: [],
  };
  environment: string;
  envForSearch: string;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private appStateService: AppStateService,
    private http: HttpClient,
    private siteConfigService: SiteConfigService,
    private envConfig: EnvConfigService,
    private loginService: LoginService,
    private sessionService: SessionService
  ) {
    this.checkUserType();
    this.environment = this.appStateService.getEnvironment();
    this.envForSearch =
      this.environment === 'preprod' ||
      this.environment === 'beta' ||
      this.environment === 'prod'
        ? 'prod'
        : 'staging'; //TODO: change staging to this.appStateService.getEnvironment() when all lower env has data
  }

  checkUserType() {
    const subject = new Subject<string>();
    this.loginService
      .isLoggedIn$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn: boolean) => {
        this.isLoggedIn = loggedIn;
        this.loginService
          .loggedInUserType$()
          ?.pipe(takeUntil(this.unsubscribe$))
          .subscribe((userType: InUserType) => {
            this.isAdvisor = userType === InUserType.ADVISOR;
            this.isInvestor = userType === InUserType.INVESTOR;
            subject.next(userType);
          });
      });
    return subject.asObservable();
  }

  getShowSections() {
    return this.showSections;
  }

  getHttpBody(searchString, start, end, collection?: string) {
    const httpParams = new HttpParams()
      .set('sort', 'relevance')
      .set('doNotSpellCheck', 'false')
      .set('selectedTab', '')
      .set('hideTabs', 'true')
      .set('locale', 'en-in')
      .set('audience', 'investor')
      .set('collection', collection ? collection : 'pages,funds,literature')
      .set('loggedIn', 'n')
      .set('query', searchString)
      .set('filters', '[]')
      .set('current', '')
      .set('start', start)
      .set('number', end)
      .set('exclude', 'no')
      .set('env', this.envForSearch);
    return httpParams;
  }

  getAutoCompleteHttpParams(searchString, start, end) {
    const httpParams = new HttpParams()
      .set('locale', 'en-in')
      .set('audience', 'investor')
      .set('collection', 'funds')
      .set('loggedIn', 'n')
      .set('query', searchString)
      .set('start', start)
      .set('number', end)
      .set('env', this.envForSearch);
    return httpParams;
  }

  getArticleHttpBody(filteredValue, start, end) {
    const body = new HttpParams()
      .set('query', '*')
      .set('audience', 'investor')
      .set('locale', 'en-in-new')
      .set('filters', filteredValue)
      .set('collection', 'pages')
      .set('start', start)
      .set('number', end)
      .set('loggedIn', 'n')
      .set('articleType', '')
      .set('env', this.envForSearch);
    return body;
  }

  getSearchHttpBody(filteredValue, searchField) {
    const body = new HttpParams()
      .set('query', searchField)
      .set('audience', 'investor')
      .set('locale', 'en-in-new')
      .set('filters', filteredValue)
      .set('collection', 'pages')
      .set('loggedIn', 'n')
      .set('articleType', '')
      .set('env', this.envForSearch);
    return body;
  }

  getBlogHttpBody(filteredBlogValues, start, end) {
    const body = new HttpParams()
      .set('query', '*')
      .set('audience', 'investor')
      .set('locale', 'en-in-new')
      .set('filters', filteredBlogValues)
      .set('collection', 'pages')
      .set('start', start)
      .set('number', end)
      .set('loggedIn', 'n')
      .set('env', this.envForSearch);
    return body;
  }

  getHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });
    return headers;
  }

  getFTISearchResults(searchString, start, end, collection) {
    const options =
      this.sessionService.apiHttpOptions;
    const searchAccountUrl =
      this.envConfig.getEnvConfig().ftiApiDomain +
      this.appStateService.getEnvConfig().searchAccountContent;
    const req1 = this.http
      .post(
        this.appStateService?.getFTSearchUrl(),
        this.getHttpBody(searchString, start, end, collection),
        {headers: this.getHeaders()}
      )
      .pipe(catchError((error) => of(error)));
    const req2 = this.http
      .get(searchAccountUrl + searchString, options)
      .pipe(catchError((error) => of(error)));
    return forkJoin([req1, req2]);
  }

  getFTIArticleResults(filteredValue, start, end) {
    return this.http.post(
      this.appStateService?.getFTIArticleListUrl(),
      this.getArticleHttpBody(filteredValue, start, end),
      {headers: this.getHeaders()}
    );
  }

  getFTIBlogResults(filteredBlogValues, start, end) {
    return this.http.post(
      this.appStateService?.getFTIArticleListUrl(),
      this.getBlogHttpBody(filteredBlogValues, start, end),
      {headers: this.getHeaders()}
    );
  }

  getFTIArticleSearchResults(filteredValue, searchField) {
    return this.http.post(
      this.appStateService?.getFTIArticleListUrl(),
      this.getSearchHttpBody(filteredValue, searchField),
      {headers: this.getHeaders()}
    );
  }

  getAutoCompleteResults(searchString, start, end) {
    return this.http.post(
      this.appStateService?.getAutoCompleteUrl(),
      this.getAutoCompleteHttpParams(searchString, start, end),
      {headers: this.getHeaders()}
    );
  }

  /**
   * returns plain or highlighted title
   * @param item search item
   */
  getGeneralTitle(item: FtSearchItem): string {
    return item.highlight?.title
      ? item.highlight?.title[0]
      : item._source.title;
  }

  /**
   * reads highlighted content for general
   * if _source.description is available, we display it
   * otherwise we cehck if highlight content is available
   * if both are not preent we return empty string
   * @param item search item
   */
  getGeneralContent(item: FtSearchItem): string {
    if (item._source?.description) {
      return item._source.description;
    }

    return item.highlight?.content ? item.highlight?.content[0] : '';
  }

  getGeneralItems() {
    const responseItems = this.results.filter((result) => {
      return result.name === pages;
    })[0].response?.hits?.hits;
    const generalItems = responseItems?.map((item) => {
      return {
        title: this.getGeneralTitle(item),
        description: this.getGeneralContent(item),
        titleLink:
          window.location.origin +
          item._source?.documentPath?.split('site-pages')[1],
      };
    });
    return generalItems;
  }

  getFundsItems() {
    const responseItems = this.results.filter((item) => {
      return item.name === funds;
    })[0].response?.hits?.hits;
    return this.extractFundData(responseItems);
  }

  getLiteratureData() {
    const responseItems = this.results?.filter((item) => {
      return item.name === literature;
    })[0].response?.hits?.hits;
    const literatureItems = responseItems?.map((item) => {
      const litPathLocal = item._source.lit_path ? item._source.lit_path : '';
      return {
        litName: this.getLiteratureTitle(item),
        litDetail: this.getLiteratureDescription(item),
        litLink: litPathLocal,
      };
    });
    return literatureItems;
  }

  /**
   *
   */
  getLiteratureTitle(item: FtSearchItem): string {
    return item?.highlight?.title
      ? item.highlight.title[0]
      : item._source.title;
  }

  /**
   *
   * @param item item with literature data
   */
  getLiteratureDescription(item: FtSearchItem): string {
    return item?.highlight?.lit_desc
      ? item.highlight.lit_desc[0]
      : item._source.lit_desc;
  }

  getSubTotal() {
    this.results.forEach((item: FtSearchResponse) => {
      if (Object.keys(item.response).length === 0) {
        return;
      }
      this.totalItems[item.name] = this.getTotal(item.response, item.name);
    });
  }

  getTotalCount() {
    return this.totalItems;
  }

  getValueToShow() {
    Object.keys(this.valueToShow).forEach((key) => {
      this.valueToShow[key] = 3;
    });
    return this.valueToShow;
  }

  setValueToShow() {
    Object.keys(this.valueToShow).forEach((key) => {
      this.valueToShow[key] = 3;
    });
  }

  getTotal(response: FtSearchResponseItem, type: string): number {
    if (response.hits === undefined) {
      return 0;
    }
    switch (type) {
      case funds:
        return Number(response.hits.total);
      case pages:
        return Number(response.hits.total.value);
      case literature:
        return Number(response.hits.total.value);
    }
  }

  /**
   *
   * @param responseItems extracts and map fund data from fund node in results
   */
  extractFundData(responseItems: FtSearchItem[]): FundItem[] {
    // TODO allShareClassTickerSymbol is not always available
    const fundItems = responseItems?.map((item) => {
      const fundDataSource: FundDataItem =
        item.inner_hits.shareClassesMatched.hits.hits[0]._source;

      const fundObj = {
        fundId: fundDataSource.fundid as FundId,
        shareClass: fundDataSource.shclcode as ShareClassCode,
        fundName: fundDataSource.title,
        identifier: fundDataSource.searchIdentifier as FundIdentifier,
      };

      const fundName = this.getFundName(item, fundDataSource);
      const fundItem = {
        fundName,
        fundsOverviewLink: this.getFundsTabLink(fundObj, TabName.OVERVIEW),
        fundsPortfolioLink: this.getFundsTabLink(fundObj, TabName.PORTFOLIO),
        fundsPerformanceLink: this.getFundsTabLink(
          fundObj,
          TabName.PERFORMANCE
        ),
        fundsHistoricalNavsLink: this.getFundsTabLink(
          fundObj,
          TabName.HISTORICAL_NAVS
        ),
        fundsIDCWLink: this.getFundsTabLink(fundObj, TabName.IDCW_HISTORY),
        fundsDocumentsLink: this.getFundsTabLink(fundObj, TabName.DOCUMENTS),
        ticker: fundDataSource.ticker,
        navdate: fundDataSource.navdate,
        navvalue: fundDataSource.navvalue,
      };

      return fundItem;
    });

    return fundItems || [];
  }

  /**
   *
   */
  getFundName(item: FtSearchItem, fundDataSource: FundDataItem) {
    return item.highlight && item.highlight.title
      ? item.highlight.title[0]
      : fundDataSource.title;
  }

  /**
   * TODO use URL service to get fund URL
   */
  getFundsTabLink(fundData, tabName): string {
    const fundLink = this.siteConfigService.getFundLink(
      fundData.fundId as FundId,
      tabName,
      fundData.fundName,
      fundData.identifier as FundIdentifier
    );
    return fundLink;
  }

  setResults(results) {
    this.results = results;
    this.getSubTotal();
  }

  getAdvisorResults(data) {
    const filteredArray = uniqBy(data?.myAccountsAdvList, (item) => {
      return item?.title;
    });
    const advList =
      filteredArray?.map((item) => {
        return {
          title: item?.title,
          titleLink: this.getInvAdvLink(item),
        };
      }) || [];
    this.totalItems.advisor = advList?.length;
    return advList;
  }

  getInvestorResults(data) {
    const filteredArray = uniqBy(data?.myAccountsInvList, (item) => {
      return item?.title;
    });

    const invList =
      filteredArray?.map((item) => {
        return {
          title: item?.title,
          titleLink: this.getInvAdvLink(item),
        };
      }) || [];
    this.totalItems.investor = invList?.length;
    return invList;
  }

  getInvAdvLink(dataObj) {
    let whereTo =
      this.appStateService.getngGuestAccountsUrl() +
      this.envConfig.getEnvConfig().ngGuestTransactNowExt;
    if (this.isLoggedIn) {
      whereTo = this.isAdvisor
        ? this.appStateService.getftiAccountsUrl() +
        this.envConfig.getEnvConfig().ngGuestAdvDashboardExt
        : this.appStateService.getftiAccountsUrl() +
        this.envConfig.getEnvConfig().ngGuestInvDashboardExt;
    }
    const url =
      dataObj.url && dataObj?.url?.includes('whrTo')
        ? whereTo + dataObj?.url
        : whereTo + 'whrTo=' + dataObj?.url;
    return url;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
